import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import HTMLReactParser from "html-react-parser"
import Slider from "react-slick"
import axios from "axios"
import "./similarProperties.scss"
import SimilarPropertyImg from "../../../images/similar-property-img.png"
import propertyLabel from "../../../images/property-label.png"
import NoImage from "../../../images/no_image_new_homes.webp"

import SimilarPropertyLogo from "../../../images/similar-property-logo.png"

import { capitalize } from "@Components/common/propertyUtils"
import {
  Similar_Newhomes,
  Newhomes_Properties,
} from "../../../queries/common_use_query"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"

import RegisterYourInterest from "@Components/forms/register-your-interest"
import Content from "../../Content/Content"

const GetAvailabel = ({ crm_id }) => {
  const { loading, error, data } = Newhomes_Properties(crm_id)
  console.log("data ==>", data)
  return (
    <>
      {loading
        ? ""
        : data?.properties?.length > 0
        ? `· ${data?.properties?.length} Available`
        : ""}
    </>
  )
}

const SimilarPropertiesNewHomes = props => {
  //
  const [propItems, setPropItems] = useState([])
  const { loading, error, data } = Similar_Newhomes(props.propertyData.id)
  useEffect(() => {
    setPropItems(data?.newDevelopments)
  }, [data])

  const [show, setShow] = useState(false)
  const [toItem, setItem] = useState("")
  const [formType, setFormType] = useState("")
  const handleClose = () => setShow(false)
  const handleShow = (form_type, email) => {
    setShow(true)
    setFormType(form_type)
    setItem(email)
  }

  // Slider settings
  var settings = {
    dots: false,
    nav: true,
    infinite: propItems?.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: propItems?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: propItems?.length > 1 ? true : false,
        },
      },
    ],
  }
  // Slider settings

  //console.log("propItems", propItems);

  var uriStr = `new-home-for-sale/`
  return (
    // propItems.length > 0 &&
    <React.Fragment>
      {propItems?.length > 0 && (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="relatednews-block opinion similar-property-newhomes">
            <Container>
              <Row>
                <Col lg={12}>
                  <h2>Similar New Homes that may Interest you...</h2>
                </Col>
              </Row>

              <Slider className="opinion-list grid overright" {...settings}>
                {propItems.map((item, index) => {
                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.images_Transforms) {
                    processedImages = item?.imagetransforms.images_Transforms
                  }

                  var location_addr = ""
                  location_addr += item?.town ? item?.town : ""
                  location_addr += item?.district ? ", " + item?.district : ""
                  location_addr += item?.country ? ", " + item?.country : ""

                  var new_hme_address = item?.address?.address1 ? item?.address?.address1 + "<br />" : ""
                  // new_hme_address += item?.address?.address2 ? item?.address?.address2 : "";
                  // new_hme_address += item?.address?.address3 ? ", "+item?.address?.address3 : "";
                  // new_hme_address += item?.address?.address4 ? ", "+item?.address?.address4 : "";

                  return (
                    <div className="property-card">
                      <div className="property-card-wrapper">
                        <div className="property-img">
                          <Link to={`/${uriStr}${item.slug}-${item.id}/`}>
                            {/* <img src={SimilarPropertyImg} alt="property" /> */}
                            {item.images && item.images?.length > 0 ?
                            <ImageTransform
                              imagesources={item.images[0].url}
                              renderer="srcSet"
                              imagename="new-developments.images.similar_homes"
                              attr={{
                                alt:
                                  item.display_address.replace(/,,/g, ",") +
                                  " - DNG Estate Agents",
                              }}
                              imagetransformresult={processedImages}
                              id={item.id}
                            />
                            :
                            <img src={NoImage} alt={item.display_address.replace(/,,/g, ",") +
                            " - DNG Estate Agents"}/>
                           }

                            {item.bedroom ? (
                              <div className="property-wrapper">
                                <div className="similar-property-tag-text">
                                  New Release of {item.bedroom} Bedroom Homes
                                </div>
                              </div>
                            ) : ""}
                            {/* <div className="property-tag">
                              <img
                                src={SimilarPropertyLogo}
                                className="img-fluid"
                                alt="New Homes logo - DNG Estate Agents"
                              />
                            </div> */}
                          </Link>
                        </div>
                        <div className="property-details">
                          <div className="property-name new_homes">
                            <Link to={`/${uriStr}${item.slug}-${item.id}/`}>
                              {/* {item.display_address.replace(/,,/g, ',')} */}
                              { new_hme_address ? HTMLReactParser(new_hme_address.replace(/,,/g, ",")) : ""}
                            </Link>
                          </div>
                          {/* {item.title && (
                            <div className="property-bedroom-details pt-2 mb-3">
                              <div className="mb-0 property-bedroom-text">
                                {capitalize(item.title)}{" "}
                                <GetAvailabel crm_id={item.crm_id} />
                              </div>
                            </div>
                          )} */}


                        {item.description &&                        
                         <Content
                         Content={item.description}
                         page="search-result"
                         uriStr={uriStr}
                         hit={item}
                         />
                        }

                          
                          <p className="newhme_info subplot">
                          {item?.price_qualifier === "POA"
                      ? "€ Price on Application"
                      : item.price_qualifier==="Guide price"
                        ?
                        `From €
                        ${item?.price?.toLocaleString()}`
                        // `From €${parseFloat(hit.price).toLocaleString()}`
                        :
                          item.price>10000?
                         `€${parseFloat(item.price)?.toLocaleString()} to 
                         €${parseFloat(item.max_price!==0?item.max_price:item.price)?.toLocaleString()}     
                          `
                          :"€ Price on Application"
                         }

                            {/* <i className="icon icon-currency"></i>
                            From €
                            {item?.price?.toLocaleString()} */}
                          </p>

                          <p className="newhme_info mb-5">
                            {location_addr && (
                              <>
                                <i className="icon icon-property-location"></i>
                                {location_addr.replace(/,,/g, ",")}
                              </>
                            )}
                          </p>

                          <div className="bottom-block">
                            <div class="valuation-cta w-100">
                              <a
                                href="javascript:;"
                                className="btn w-100"
                                onClick={() =>
                                  handleShow("register_your_interest", item)
                                }
                              >
                                Register your interest
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </Container>
          </div>
        </ScrollAnimation>
      )}

      <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
      >
        <>
          {formType === "register_your_interest" && (
            <>
              <Modal.Header closeButton className="contact-close-btn">
                <Modal.Title className="w-100">
                  Register Your Interest
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className="form">
                <RegisterYourInterest
                  toemail={toItem?.crm_negotiator_id?.Email}
                  propertyData={toItem}
                  pro_url={`/${uriStr}${toItem.slug}-${toItem.id}`}
                />
              </Modal.Body>
            </>
          )}
        </>
      </Modal>
    </React.Fragment>
  )
}

export default SimilarPropertiesNewHomes
