import { Link } from "gatsby"
import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col, Modal } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import { useLocation } from "@reach/router"
import ShowMoreText from "react-show-more-text"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import $ from "jquery"
import "./newhomesPropertyDescription.scss"
import contactImg1 from "../../../../images/contact-1.jpg"
import NoImage from "../../../../images/no-image.png"

import { capitalize } from "@Components/common/propertyUtils"
import TeamForm from "@Components/forms/teamcontact"
import ReactMarkdown from "react-markdown"
import DetailContent from "../../../Content/DetailContent"

const NewHomesPropertyDescription = props => {
  // Property description show more and less
  const [readMore, setReadMore] = useState(false)
  const [show, setShow] = useState(false)
  const [formType, setFormType] = useState("")

  const handleClose = () => setShow(false)
  const handleShow = form_type => {
    setShow(true)
    setFormType(form_type)
  }

  const [negoDet, setNegoDet] = useState()

  // Property description show more and less

  // Social share
  const location = useLocation()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const [Shareicons, setShareicons] = React.useState(false)

  const openShareicons = () => {
    setShareicons(true)
    if (Shareicons === true) {
      setShareicons(false)
    }
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false)

  const openBookViewformModal = () => {
    setBookViewingformOpen(true)
  }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  var property_type = props?.propertyData?.building?.length > 0 ? props?.propertyData?.building?.join(", ") : ""

  var developer_logo = props?.propertyData?.developer_logo?.url || props?.propertyData?.developer_logo2?.url

  return (
    <React.Fragment>
      {props?.propertyData && (
        <Container>
          <Row>
            <Col lg="4">
              <div className="property-info newhome_details">
                <div className="heading-wrap">
                  {props?.propertyData?.display_address && (
                    <h3>
                      {props?.propertyData?.display_address.replace(/,,/g, ",")}
                    </h3>
                  )}

                  <div className="like-share d-none d-md-flex d-lg-none">
                    <Link to="/">
                      <i className="icon-heart"></i>
                    </Link>
                    <span className="social-share-wrapper">
                      <a
                        href="javascript:void(0)"
                        className="share"
                        onClick={openShareicons}
                      >
                        <i className="icon-share"></i>
                      </a>
                      {Shareicons && (
                        <div
                          onClick={openShareicons}
                          className="property-share-social-icons mobile-details-socila-share"
                        >
                          <FacebookShareButton
                            onClick={() => trackerShare("FacebookShareButton")}
                            url={shareurl}
                            className="my-share-button facebook-share"
                          >
                            <FacebookIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            onClick={() => trackerShare("TwitterShareButton")}
                            url={shareurl}
                            className="my-share-button twitter-share"
                          >
                            <TwitterIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            onClick={() => trackerShare("LinkedinShareButton")}
                            url={shareurl}
                            className="my-share-button linkedin-share"
                          >
                            <LinkedinIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            onClick={() => trackerShare("WhatsappShareButton")}
                            url={shareurl}
                            className="my-share-button whatsapp-share"
                          >
                            <WhatsappIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </WhatsappShareButton>
                          {/* <EmailShareButton url='#' onClick={()=> { trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                        <EmailIcon size={32} round={false} borderRadius={`10`} />
                                                    </EmailShareButton> */}
                        </div>
                      )}
                    </span>
                  </div>

                  {props?.propertyData?.building?.length > 0 && (
                    <div className="bottom-block property_type">
                        <div className="bedroom-count">
                            {property_type}
                        </div>
                    </div>
                  )}
                </div>

                <div className="property-price">
                {props?.propertyData?.price_qualifier === "POA"
                      ? "€ Price on Application"
                      : props?.propertyData.price_qualifier==="Guide price"
                        ?`From €${parseFloat(props?.propertyData.price)?.toLocaleString()}`:
                        props?.propertyData?.price === props?.propertyData.max_price
                        ? `From €${parseFloat(props?.propertyData?.price).toLocaleString()}`
                        :
                        props?.propertyData.price>10000?
                         `€${parseFloat(props?.propertyData.price)?.toLocaleString()} ${props?.propertyData.max_price ? `to €${parseFloat(props?.propertyData.max_price!==0?props?.propertyData.max_price:props?.propertyData.price)?.toLocaleString()}` : ``}     
                          `
                          :"€ Price on Application"
                         }

                      </div>

                <div className="bottom-block">
                  {/* <div className="bedroom-count">
                    {props?.propertyData?.bedroom > 0 && (
                      <>
                        <i className="icon-bed"></i>
                        {props?.propertyData?.bedroom > 1
                          ? props?.propertyData?.bedroom + " bedrooms"
                          : props?.propertyData?.bedroom + " bedroom"}
                      </>
                    )}
                  </div>*/}

                  {/* <div className="property-label">
                      <img src={propertyLabel} alt="label" />
                  </div> */}

                {props?.propertyData?.floorarea_min > 0 &&
                    <div className="property-area">
                        <i className="icon-cube"></i>
                        {props.propertyData.floorarea_type !== "squareMetres" ?  
                          (props?.propertyData?.floorarea_min / 10.7639).toFixed(1).replace(".0", "")
                        : 
                          props?.propertyData?.floorarea_min} {" m"}
                      <sup>2</sup>
                  </div>
                }

                  {props?.propertyData?.extras &&
                    props?.propertyData?.extras?.extrasField?.pBERRating && (
                      <div className="property-label">
                        <div className="d-flex justify-content-start align-items-center property-ber-rating">
                          <div className="ber-black">BER</div>
                          <div
                            className={
                              "ber-green " +
                              props?.propertyData?.extras?.extrasField?.pBERRating.toLowerCase()
                            }
                          >
                            {
                              props?.propertyData?.extras?.extrasField
                                ?.pBERRating
                            }
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {developer_logo && (
                <div className="newhomes-dev-wrapper">
                  <img
                    src={developer_logo}
                    alt={"Developer Logo - DNG Estate Agents"}
                    className="mb-3"
                  />
                  <div className="new-homes-developer-name">
                    {props?.propertyData?.developer}
                  </div>
                </div>
              )}
            </Col>
            <Col lg="1"></Col>
            <Col lg="7">
              <div className="property-description new-homes-description">
                {/* <div className={!readMore ? "description-wrap" : "description-wrap height-auto"}>
                                    <p>{HTMLReactParser(props?.propertyData?.description)}</p>
                                </div> */}
                {/* <div className="description-link">
                                    <span className="read-more" onClick={() => setReadMore(!readMore)}>{!readMore ? "Show Full Description" : "Show Half Description"}</span>
                                </div> */}

                <div className={"description-wrap height-auto"}>
                  <p>
                     {/* <ShowMoreText
                      lines={7}
                      more="Show More"
                      less="Show Less"
                      className="content-css description-link"
                      anchorClass="my-anchor-css-class read-more"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >  */}
                      {/* <ReactMarkdown source={props?.propertyData?.description} allowDangerousHtml/> */}
                      <DetailContent Content={props?.propertyData?.description}/>
                    {/* </ShowMoreText> */}
                  </p>
                </div>
              </div>

              <div className="contact-sidebar newhomes-contact-sidebar">
                <div className="dealer-info">
                  <div className="w-100 d-md-flex justify-content-between">
                    {props?.nego_info?.teams.length > 0 &&
                      props?.nego_info?.teams.map((item, i) => {
                        return (
                          <div className="dealer-wrap">
                            <div className="img-wrap">
                              {item?.Image?.url ? (
                                <img
                                  src={item?.Image?.url}
                                  alt={item?.Name + "DNG Estate Agents"}
                                />
                              ) : (
                                <img src={NoImage} alt="contact" />
                              )}
                            </div>
                            <div className="dealer-right">
                              <span className="dealer-name">{item?.Name}</span>
                              <span className="dealer-position">
                                {item?.Designation}
                              </span>
                              <span className="dealer-contact">
                                <a href={`tel:${item?.Phone}`}>{item?.Phone}</a>{" "}
                                /{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    handleShow("member_form")
                                    setNegoDet(item)
                                  }}
                                >
                                  Email
                                </a>
                              </span>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
      >
        <>
          {formType === "member_form" && (
            <>
              <Modal.Header closeButton className="contact-close-btn">
                <Modal.Title className="w-100">
                  Email to {negoDet?.Name}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className="form">
                <TeamForm toemail={negoDet?.Email} />
              </Modal.Body>
            </>
          )}
        </>
      </Modal>
    </React.Fragment>
  )
}

export default NewHomesPropertyDescription
