import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem } from 'react-bootstrap';
import { Link } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import axios from "axios"
import "./similarProperties.scss"
import SimilarPropertyImg from '../../../images/similar-property-img.png';
import propertyLabel from '../../../images/property-label.png';
import NoImage from '../../../images/no-image.png';

import {capitalize} from "@Components/common/propertyUtils";
import {Newhomes_Properties} from '../../../queries/common_use_query';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"

const PropertyDevelopment = (props) => {

    const [propItems, setPropItems] = useState([])

    const {loading, error, data} = Newhomes_Properties(props.propertyData.id);

    useEffect(()=>{
        setPropItems(data?.properties)
    },[data])

    // Slider settings
    var settings = {
		dots: false,
		nav: true,
		infinite: propItems?.length > 3 ? true : false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
        centerMode: false,
		responsive: [
			{
				breakpoint: 3000,
				settings: {
					slidesToShow: 3,

				}
			},
			{
				breakpoint: 1500,
				settings: {
					slidesToShow: 3,

				}
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 2,
                    infinite: propItems?.length > 2 ? true : false,
				}
			},
			{
				breakpoint: 675,
				settings: {
					slidesToShow: 1,
                    infinite: propItems?.length > 1 ? true : false,
				}
			},
		]
	};
    // Slider settings

    //var uriStr = `new-home-for-sale/`

    //console.log("propItems", propItems);

    return (
        // propItems.length > 0 &&
        <React.Fragment>
            {propItems?.length > 0 &&
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <div className="relatednews-block property-development-carousel">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <h2>Property types within this development</h2>
                            </Col>
                        </Row>

                        <Slider className='opinion-list grid overright' {...settings}>

                        {propItems.map((item, index) => {

                            let processedImages = JSON.stringify({})
                            if (item?.imagetransforms?.images_Transforms) {
                                processedImages = item?.imagetransforms.images_Transforms
                            }

                            var location_addr = "";
                                location_addr += item.address?.address2 ? item.address?.address2+' ' : '';
                                location_addr += item.address?.address3 ? item.address?.address3+' ' : '';
                                location_addr += item.address?.address4 ? item.address?.address4+' ' : '';

                            // property details url structure
                            let uriStr = ""

                            if (item.search_type === "sales" && item.department === "residential" || item.department ==="NH" || item.department ==="new_developments_residential") {
                                uriStr = `property-for-sale/`
                            } else if ( item.search_type === "lettings" && item.department === "residential" ) {
                                uriStr = `property-to-rent/`
                            } else if ( item.search_type === "sales" && item.department === "commercial") {
                                uriStr = `commercial-property-for-sale/`
                            } else if ( item.search_type === "lettings" && item.department === "commercial") {
                                uriStr = `commercial-property-to-rent/`
                            }
                           
                            // property details url structure

                            var image_url = item.images?.length > 0 ? item.images[0].srcUrl || item.images[0].url : "";
                        
                            return(
                                <div className='property-card'>
                                    <div className="property-card-wrapper">
                                        <div className="property-img">
                                            <Link to={`/${uriStr}${item.slug}-${item.id}`}>
                                                {image_url ? 
                                                    <img src={image_url} alt="property" />
                                                :
                                                    <img src={NoImage} alt="property" />
                                                }

                                                {/* <ImageTransform
                                                    imagesources={item.images[0].url}
                                                    renderer="srcSet"
                                                    imagename="property.images.detail2"
                                                    attr={{
                                                        alt: item.display_address.replace(/,,/g, ','),
                                                    }}
                                                    imagetransformresult={processedImages}
                                                    id={item.id}
                                                ></ImageTransform> */}
                                            </Link>
                                        </div>
                                        <div className="property-details">
                                            <div className="property-name">
                                                <Link to={`/${uriStr}${item.slug}-${item.id}`}>
                                                    {item.display_address.replace(/,,/g, ',')}
                                                </Link>
                                            </div>
                                            {item.title &&
                                                <div className="property-bedroom-details pt-0 mb-3">
                                                    <div className="mb-0 property-bedroom-text">
                                                        {capitalize(item.title)}
                                                    </div>
                                                </div>
                                            }

                                            {/* {item.price ?
                                            <p>
                                                <i className="icon-currency"></i>From €{item.price.toLocaleString()}
                                            </p>
                                            :
                                            <p className="developments-qualifier">
                                                {item?.price_qualifier==="POA"?<p className="price-on-app"><i className="icon-currency"></i>Price on Application</p>:item?.price_qualifier}
                                            </p>
                                            } */}
                                            
                                            {item?.price_qualifier === "POA"
                                                ? <p>€ Price on Application</p>
                                                : item.price_qualifier==="Guide price"
                                                    ?
                                                    <p>
                                                        <i className="icon-currency"></i>
                                                    {`From €
                                                    ${item?.price?.toLocaleString()}`}
                                                    </p>
                                                    // <i className="icon-currency"></i>
                                                
                                                    // `From €${parseFloat(hit.price).toLocaleString()}`
                                                    :
                                                    item.price>10000?
                                                    <p>
                                                        {
                                                            `€${parseFloat(item.price)?.toLocaleString()}    
                                                            `
                                                        }
                                                    </p>
                                                    
                                            :<p>€ Price on Application</p>
                                            }
                                                                        

                                            
                                            <p className="mb-5">
                                                {location_addr && <>
                                                    <i className="icon-property-location"></i> 
                                                    {location_addr}
                                                    </>
                                                }
                                            </p>
                                            

                                            <div className="bottom-block">
                                                <Link className="w-100" to={`/${uriStr}${item.slug}-${item.id}`}>
                                                    <div class="valuation-cta w-100">
                                                        <button type="button" class="btn w-100">View Details</button>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                        }
                        </Slider>
                    </Container>
                </div>
            </ScrollAnimation>
            }
        </React.Fragment>
    )
}

export default PropertyDevelopment;